<template>
  <div style="display: flex; align-items: center">
    <div style="width: 150px; margin-right: 6px">
      <SelectTree
        v-model="formatValue.organizationUUID"
        :options="organizationTree"
        :replace-fields="{
          itemText: 'name',
          itemKey: 'uuid',
          itemChildren: 'children',
        }"
        box-shadow
        custom
        placeholder="请选择机构名称"
        :disabled="isSubbranch"
      ></SelectTree>
    </div>
    <Tags
      v-model="formatValue.monitorSearchDateType"
      :options="searchDate"
      :replace-fields="{
        lable: 'msg',
        value: 'code',
      }"
      @set:value="formatValue.range = []"
    />
    <DatePicker
      v-model="formatValue.range"
      box-shadow
      custom
      range
      :allow-clear="false"
      @set:value="
        (value) =>
          $emit('set:value', {
            ...formatValue,
            range: value,
            monitorSearchDateType: undefined,
          })
      "
    ></DatePicker>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SelectTree from '@/views/screen/components/SelectTree.vue';
import Tags from '@/views/screen/components/Tags.vue';
import DatePicker from '@/views/screen/components/DatePicker.vue';
export default {
  //组件注册
  components: { SelectTree, Tags, DatePicker },
  model: {
    prop: 'value',
    event: 'set:value',
  },
  //组件传值
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {
    formatValue: {
      get() {
        return this.value;
      },
    },
    ...mapState({
      isSubbranch: (state) => state.screenStore.isSubbranch,
      organizationTree: (state) => state.screenStore.organizationTree,
      searchDate: (state) =>
        state.screenStore.commondict['MonitorSearchDateTypeEnum']
          ? state.screenStore.commondict['MonitorSearchDateTypeEnum']
          : [],
    }),
  },
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped></style>
